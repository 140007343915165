import React from 'react';
import ProductDetail from '../../components/ProductDetail';
import Layout from '../../components/layout/Layout';
import products from '../../data/products';

export default () => (
  <Layout>
    <ProductDetail product={products[1]} />
  </Layout>
);
